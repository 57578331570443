import { browser } from '$app/environment'
import posthog from 'posthog-js'

export const load = async () => {
  if (browser && window.location.hostname !== 'localhost') {
    posthog.init('phc_GZIVJ8RyLzH7Rt3lMMMEujN78Z3RSDRkxhTPza3gdQt', {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
      capture_pageleave: false,
    })
  }
  return
}
