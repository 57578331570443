<script>
  import '../css/app.css'
  import '../css/fonts.css'
  import '../css/input.css'
  import '../css/prose.css'

  import posthog from 'posthog-js'
  import { browser } from '$app/environment'
  import { beforeNavigate, afterNavigate } from '$app/navigation'

  if (browser) {
    beforeNavigate(() => posthog.capture('$pageleave'))
    afterNavigate(() => posthog.capture('$pageview'))
  }
</script>

<svelte:head>
  <link
    rel="preload"
    href="/fonts/figtree.woff2"
    crossorigin="anonymous"
    as="font"
    type="font/woff2"
  />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:site" content="@turbomapsapp" />
</svelte:head>

<slot />
